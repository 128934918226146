body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.pac-container {
  z-index: 10000 !important;
}


/*ESTILOS DE LAS ALERTAS*/
 .swalert {
        z-index: 2000 !important;
  }
  .swal-button {
        /* background-color: "#90594C"; */
  }
      .swal-title {
        /* font-family: 'Baskervville';
        font-weight: normal */
      }

      .swal2-confirm{
        min-width: 150px !important;
        font-size: 20px !important;
       /* font-family: 'Baskervville'*/
      }

      .swal2-cancel{
        min-width: 150px !important;
        font-size: 20px !important;
        /* font-family: 'Baskervville' */
      }

      .swal2-icon{
        border-color:white !important
      }

      .swal2-title{
        padding-top:20px !important;
        /* font-family: 'Baskervville'; */
        font-weight: normal
      }
